<template>
    
      
        <v-carousel cycle style="height: 800px; ">
          <v-carousel-item
            src="../assets/Carrusel/pinche.jpeg"
            cover
          ></v-carousel-item>
  
          <v-carousel-item
            src="../assets/Carrusel/vaca.jpg"
            cover
          ></v-carousel-item>
  
          <v-carousel-item
            src="../assets/Carrusel/limus.jpg"
            cover
          ></v-carousel-item>
          <v-carousel-item
            src="../assets/Carrusel/vacas.jpg"
            cover
          ></v-carousel-item>
        </v-carousel>
     
      
   
  </template>
  <script>

  export default {
    name: 'AppCarrusel',
   
    data: () => ({
    
     
      
    }),
  }
  </script>